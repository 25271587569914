/* eslint-disable sonarjs/sonar-no-unused-class-component-methods */
import React from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { BUS_GLOBAL_LANGUAGE } from '../../common/auth/bus-topics';
import { IWidgetConfigurationContext, WidgetConfigurationContext } from '../../common/configuration';
import { SgwtWidgetName, startWidgetMonitoring } from '../../common/monitoring';
import { Translator } from '../../common/sgwt-i18n';
import { isDebugForced, warnSSOv1NotSupportedAnymore } from '../../common/sgwt-widgets-utils';
import { widgetize, widgetPropsBoundEvent } from '../../common/widgetize';
import SplashScreen from './components/SplashScreen';
import translator from './shared/sgwt-splash-screen.i18n';
import { ISgwtSplashScreenProps, ISgwtSplashScreenState } from './sgwt-splash-screen.types';

import './sgwt-splash-screen.scss';

export interface SgwtSplashScreenPublicFields {
  show: () => void;
  showHistory: () => void;
  onShow: () => void;
  onNotShown: () => void;
  onHide: () => void;
}

export class SgwtSplashScreen extends React.Component<ISgwtSplashScreenProps, ISgwtSplashScreenState> {
  static readonly contextType = WidgetConfigurationContext;
  public static readonly is = 'sgwt-splash-screen';
  private debugEnabled = false;
  private translator: Translator = translator;
  private widgetConfiguration: WidgetConfiguration;
  private propsBoundEvent = widgetPropsBoundEvent(SgwtSplashScreen.is);
  private onWidgetReady = (): void => {
    this.props.onReady({});
  };

  constructor(props: ISgwtSplashScreenProps, context: IWidgetConfigurationContext) {
    super(props);
    this.debugEnabled = this.props.debug || isDebugForced();
    this.widgetConfiguration = context!.widgetConfiguration;
    const languageSubscription = this.widgetConfiguration.bus.subscribe<string>(
      BUS_GLOBAL_LANGUAGE,
      (language?: string) => {
        if (language) {
          this.translator.changeCurrentLanguage(language);
        }
      },
    );
    this.state = {
      visible: props.visible || false,
      mode: props.mode || 'active',
      languageSubscription,
    };
    if (props.sgConnectSupport) {
      if (props.sgConnectSupport.toLowerCase() === 'sso-v1') {
        warnSSOv1NotSupportedAnymore(SgwtWidgetName.SPLASH_SCREEN);
      } else {
        this.widgetConfiguration.warn('The attribute "sg-connect-support" is not supported anymore.');
      }
    }
  }

  public show(): void {
    this.logDebug('show()');
    this.setState({ ...this.state, visible: true, mode: 'active' });
  }

  public showHistory(): void {
    this.logDebug('showHistory()');
    this.setState({ ...this.state, visible: true, mode: 'history' });
  }

  onShow = () => {
    if (!this.state.visible) {
      this.setState({ ...this.state, visible: true });
    }
    this.props.onShow({});
  };

  // Called when the modal has been asked to be displayed but not displayed, for example if no screen to display.
  onNotShown = () => {
    if (this.state.visible) {
      this.setState({ ...this.state, visible: false });
    }
  };

  onHide = () => {
    if (this.state.visible) {
      this.setState({ ...this.state, visible: false });
    }
    this.props.onHide({});
  };

  componentDidMount() {
    this.logDebug('component ready');
    document.addEventListener(this.propsBoundEvent, this.onWidgetReady);
    startWidgetMonitoring(SgwtWidgetName.SPLASH_SCREEN, this.props.applicationId || this.props.application, {
      manualActivation: this.props.manualActivation,
      mode: this.props.mode,
    });
  }

  public componentWillUnmount(): void {
    if (this.state.languageSubscription) {
      this.widgetConfiguration.bus.unsubscribe(this.state.languageSubscription);
    }
    document.removeEventListener(this.propsBoundEvent, this.onWidgetReady);
  }

  private logDebug(...messages: any[]) {
    if (this.debugEnabled) {
      this.widgetConfiguration.log('[sgwt-splash-screen::debug]', ...messages);
    }
  }

  render() {
    const applicationId = this.props.applicationId || this.props.application;
    if (!applicationId) {
      return null;
    }
    return (
      <SplashScreen
        applicationId={applicationId}
        debug={this.debugEnabled}
        manualActivation={this.props.manualActivation}
        mode={this.state.mode}
        onHide={this.onHide}
        onNotShown={this.onNotShown}
        onShow={this.onShow}
        translator={this.translator}
        visible={this.state.visible}
      />
    );
  }
}

widgetize(SgwtSplashScreen, SgwtSplashScreen.is, {
  attributes: [
    { name: 'application', type: 'string' },
    { name: 'application-id', type: 'string' },
    { name: 'mode', type: 'string' },
    { name: 'visible', type: 'string' },
    { name: 'manual-activation', type: 'boolean' },
    { name: 'sg-connect-support', type: 'string' },
    { name: 'debug', type: 'boolean' },
  ],
  events: [
    {
      name: `${SgwtSplashScreen.is}--ready`,
      functionName: 'onReady',
    },
    {
      name: `${SgwtSplashScreen.is}--hide`,
      functionName: 'onHide',
    },
    {
      name: `${SgwtSplashScreen.is}--show`,
      functionName: 'onShow',
    },
  ],
  deferredFunctions: ['show', 'showHistory'],
});
