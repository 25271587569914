export const SPLASH_SCREEN_ENDPOINTS = {
  development: {
    api: 'https://sgwt-api-sgwt-splash-screen-dev.sgmarkets.world.socgen/api/v1',
    disclaimer: {
      api: 'https://api-s-uat.sgmarkets.com/services/insight/serviceboard/private/v2/userdisclaimers?application=',
      page: 'https://www-uat.sgmarkets.com/disclaimer/page',
      restrictedCountry: 'https://www-uat.sgmarkets.com/disclaimer/countryrestricted',
    },
    feedback: 'https://sgwt-api-sgwt-help-center-dev.sgmarkets.world.socgen/api/v1/feedback',
  },
  homologation: {
    api: 'https://api-s-uat.sgmarkets.com/widget/webtoolkit/splash-screen/v1',
    disclaimer: {
      api: 'https://api-s-uat.sgmarkets.com/services/insight/serviceboard/private/v2/userdisclaimers?application=',
      page: 'https://www-uat.sgmarkets.com/disclaimer/page',
      restrictedCountry: 'https://www-uat.sgmarkets.com/disclaimer/countryrestricted',
    },
    feedback: 'https://api-s-uat.sgmarkets.com/widget/webtoolkit/help-center/v1/feedback',
  },
  production: {
    api: 'https://shared.sgmarkets.com/api/v1', // TODO
    disclaimer: {
      api: 'https://api-s.sgmarkets.com/services/insight/serviceboard/private/v2/userdisclaimers?application=',
      page: 'https://www.sgmarkets.com/disclaimer/page',
      restrictedCountry: 'https://www.sgmarkets.com/disclaimer/countryrestricted',
    },
    feedback: 'https://shared.sgmarkets.com/api/v1/feedback', // TODO (API from the Help-Center)
  },
};

export interface ISplashScreenDisclaimerInfo {
  api: string;
  page: string;
  restrictedCountry: string;
}

export interface ISplashScreenEnvEndpoints {
  api: string;
  disclaimer: ISplashScreenDisclaimerInfo;
  feedback: string;
}

export interface ISplashScreenEndpoints {
  development: ISplashScreenEnvEndpoints;
  homologation: ISplashScreenEnvEndpoints;
  production: ISplashScreenEnvEndpoints;
}
