import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { BUS_ACCESS_TOKEN, BUS_GRANTED_SCOPE } from './auth/bus-topics';
import { registerEvent, SgwtWidgetName } from './monitoring';

// Functions required from @sgwt/connect-core library
export interface ISgwtConnectLibrary {
  discardAuthorization: () => void;
  discardAuthorizationLocally: () => void;
  getAuthHttpHeader?: () => string; // Removed in v0.4.0
  getAuthorizationHeader?: () => string; // Added in v0.4.0
  getGrantedScope?: () => string; // Added in v0.6.0
  getIdTokenClaims?: () => string; // Added in v0.9.0
  getJWTClaims?: () => string; // Removed in v0.9.0
  on: (event: string, callback: () => void) => void;
  // `off` is a shortcut to `removeListener` in `events` library, introduced in v3.0.0.
  // Since SGWT Widgets is built with an old version (`1.1.1`) of `events` library,
  // `.off` shortcut does not exist, so we use `removeListener` instead.
  off?: (event: string, callback: () => void) => void;
  removeListener?: (event: string, callback: () => void) => void;
  requestAuthorization: () => void;
}

/**
 * Check if the current environment is a production environment.
 */
export function isProductionEnvironment(widgetConfiguration?: WidgetConfiguration) {
  const env: string | undefined = widgetConfiguration
    ? widgetConfiguration.environment
    : window.SGWTWidgetConfiguration?.environment;
  return !env || env.toLowerCase() === 'production' || env.toLowerCase() === 'prod';
}

/**
 * Get the SGWT Connect interface, either from <sgwt-connect> widget or from `window.sgwtConnect` variable.
 */
export function getSgwtConnectLibrary(): ISgwtConnectLibrary | undefined {
  // First, try to retrieve the <sgwt-connect> widget...
  const sgwtConnectWidget = document.querySelector('sgwt-connect');
  if (sgwtConnectWidget && sgwtConnectWidget.sgwtConnect) {
    return sgwtConnectWidget.sgwtConnect as unknown as ISgwtConnectLibrary;
    // In case the widget is present in the page without sgwtConnect instance, we will check in the window properties.
    // This case should not really happen, since it means that the <sgwt-connect> widget is used in the passive mode,
    // and the instance has not been passed to it. But we never know...
  }
  // Now, find the sgwtConnect instance in window.
  return window.sgwtConnect;
}

/**
 * Get Claims from SGWTConnect. The function was `getJWTClaims()` until v.0.9.0 when it becomes `getIdTokenClaims()`.
 */
export const getClaimsFromSgwtConnect = (sgwtConnect: any | undefined): any | null => {
  if (sgwtConnect) {
    // For v0.9.0 or higher
    if (typeof sgwtConnect.getIdTokenClaims !== 'undefined') {
      return sgwtConnect.getIdTokenClaims();
    }
    // For v0.8.0 or lower
    if (typeof sgwtConnect.getJWTClaims !== 'undefined') {
      return sgwtConnect.getJWTClaims();
    }
  }
  return null;
};

/**
 * Sometimes it can be convenient to force the debug mode even if the `debug="true"` attribute in not set.
 * We can do that by setting a `sgwt-widgets.debug = true` in localStorage...
 */
export function isDebugForced() {
  const dbg = localStorage.getItem('sgwt-widgets.debug');
  return dbg !== null && dbg.toLowerCase() === 'true';
}

/**
 * Check if the given object is null / undefined or empty (i.e. `{}`).
 */
export function emptyObject(obj?: object | null) {
  return !obj || Object.keys(obj).length === 0;
}

// Existing modes for the SGWT Widgets
export type WidgetsMode = 'sg-markets' | 'b2b2c' | 'sgef';

/**
 * Check if the SG Markets mode is / should be enabled.
 */
export function modeSGMarketsEnabled(mode?: string | null): boolean {
  if (mode?.toLowerCase() === 'sg-markets') {
    return true;
  }
  if (mode?.toLowerCase() === 'sgef') {
    return false;
  }
  const host = document.location.host.toLowerCase();
  return (
    host.indexOf('.sgmarkets.com') > -1 ||
    host.indexOf('.sgmarkets-next.com') > -1 ||
    host.indexOf('.sgmarkets.world.socgen') > -1
  );
}

/**
 * Define which mode is enabled for the current widget.
 * @param mode mode defined in the `mode` attribute of the widget.
 * @returns the current mode, or `null` if the mode is not defined or unknown.
 */
export function whichMode(mode?: string | null): WidgetsMode | null {
  if (mode?.toLowerCase() === 'b2b2c') {
    return 'b2b2c';
  }
  if (mode?.toLowerCase() === 'sgef') {
    return 'sgef';
  }
  return modeSGMarketsEnabled(mode) ? 'sg-markets' : null;
}

/**
 * Check if the widget is currently running under one of the expected modes.
 */
export function isInMode(mode: WidgetsMode | null, expectedModes: WidgetsMode[]): boolean {
  return !!mode && expectedModes.indexOf(mode) > -1;
}

/**
 * Get the authorization token from sgwtConnect library or from bus...
 */
export function getAuthorizationToken(widgetConfiguration: WidgetConfiguration): string | undefined {
  // Get the sgwtConnect from the widget or from the window object...
  const sgwtConnect = getSgwtConnectLibrary();
  // Get the authorization token...
  const authorization: string | undefined = sgwtConnect
    ? typeof sgwtConnect.getAuthorizationHeader === 'function'
      ? sgwtConnect.getAuthorizationHeader()
      : typeof sgwtConnect.getAuthHttpHeader === 'function'
        ? sgwtConnect.getAuthHttpHeader()
        : undefined
    : widgetConfiguration.bus.dangerouslyGetCurrentValue<string | undefined>(BUS_ACCESS_TOKEN);
  return authorization;
}

/**
 * Check if the widget is not currently running under one of the expected modes.
 */
export function isNotInMode(mode: WidgetsMode | null, unexpectedModes: WidgetsMode[]): boolean {
  return !mode || unexpectedModes.indexOf(mode) === -1;
}

/**
 * Check if the current session has a specific scope. We first try to get the granted scopes from the Widget bus
 * (normally populated by <sgwt-connect>), then by the `window.sgwtConnect` instance, if exists.
 * When the call returns `undefined`, it means that the list of granted scopes was not found.
 */
export function hasGrantedScope(widgetConfiguration: WidgetConfiguration, scope: string): boolean | undefined {
  let grantedScopes = widgetConfiguration.bus.dangerouslyGetCurrentValue<string | null>(BUS_GRANTED_SCOPE);
  if (grantedScopes === null || typeof grantedScopes === 'undefined') {
    const sgwtConnect = getSgwtConnectLibrary();
    if (typeof sgwtConnect !== 'undefined' && sgwtConnect.getGrantedScope) {
      grantedScopes = sgwtConnect.getGrantedScope();
    } else {
      widgetConfiguration.debug(
        `Could not define if the scope "${scope}" is granted as we are not able to find the list of granted scopes.`,
      );
      return undefined;
    }
  }
  return (
    grantedScopes !== null &&
    typeof grantedScopes !== 'undefined' &&
    grantedScopes
      .trim()
      .toLowerCase()
      .split(/\s+/)
      .some((s) => s === scope.trim().toLowerCase())
  );
}

/**
 * SSO v1 authentication is not supported anymore. We trace that information.
 */
export function warnSSOv1NotSupportedAnymore(widgetName: SgwtWidgetName) {
  console.log('*****************************************************************************************');
  console.error(`The SSO-v1 authentication system is not supported anymore for the widget ${widgetName}.`);
  console.log('*****************************************************************************************');
  registerEvent(widgetName, 'sso-v1-authentication');
}

/**
 * Add authentication information in the request header, depending on the authentication mode (sso-v1 or sg-connect-v2).
 */
export function addAuthenticationInfoInRequestHeader(
  options: any,
  widgetConfiguration: WidgetConfiguration,
  authentication?: string,
) {
  if (authentication === 'sg-connect-v1' || authentication === 'sso-v1') {
    options.credentials = 'include'; // or 'same-origin'*/
  } else if (authentication === 'sg-connect-v2') {
    // For SG Connect v2 authentication,
    const authorization = getAuthorizationToken(widgetConfiguration);
    if (authorization) {
      options.headers.Authorization = authorization;
    } else {
      console.debug(
        '[WARN] The widget is configured with the mode "sg-connect-v2" but the sgwtConnect library is not available on `window` ' +
          'and the Widget bus does not contains any information about the authentication...',
      );
    }
  }
  return options;
}

/**
 * Check if there is a user with a token.
 */
export function isUserAuthorized(widgetConfiguration: WidgetConfiguration): boolean {
  const authorization = getAuthorizationToken(widgetConfiguration);
  return !!authorization;
}

/**
 * Check the response status. If not 2xx, then throw an Error.
 */
export async function checkResponseStatus(response: Response): Promise<Response> {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    let errorMessage = response.statusText;
    try {
      const json = await response.json();
      errorMessage = `[${json.status || json.statusCode}] ${json.message || JSON.stringify(json)}`;
    } catch (_e) {
      // ignore error
    }
    throw new Error(errorMessage);
  }
}

/**
 * Execute a function if the custom element is present in the page, or execute it when it becomes available.
 * There are mainly two situations where this function is called:
 * 1. The widget A just needs to know if widget B is present in the page, but does not need B to be ready.
 *    It's the case with the Account Center that needs to know if the Help Center is present or not.
 * 2. The widget A needs to call an API from widget B. So it needs to know if the widget is present, but
 *    also when this widget is accessible (i.e. `document.querySelector(customElement)` does not return `null`).
 *    This is the purpose of the parameter `checkOptions`.
 *    `documentDefinition`: checks if the custom element need to be available in the document.
 *    `propName`: checks if the property according to the given name is available on the custom element.
 *    A max retries is defined, because if a property name to check is defined,
 *    a property name which never exists on the custom element can cause an infinite loop.
 */

type AvailabilityCheckOptions = {
  documentDefinition: boolean;
  propName?: string;
};

const MAX_RETRIES_WHEN_CUSTOM_ELEMENT_DEFINED = 30;

export function executeWhenCustomElementIsPresent(
  customElement: string,
  checkOptions: AvailabilityCheckOptions,
  cb: () => void,
  nbCallStack = 0,
) {
  // First check if we found the element in the DOM...
  const webComponent = document.querySelector<Element & Record<string, unknown>>(customElement);

  if (
    webComponent !== null &&
    checkOptions.propName &&
    !Object.hasOwn(webComponent, checkOptions.propName) &&
    nbCallStack > MAX_RETRIES_WHEN_CUSTOM_ELEMENT_DEFINED
  ) {
    throw new Error(
      [
        `${executeWhenCustomElementIsPresent.name} exceed ${MAX_RETRIES_WHEN_CUSTOM_ELEMENT_DEFINED} retries`,
        `the property ${checkOptions.propName} seems not defined on the custom element ${customElement}`,
      ].join(', '),
    );
  }

  if (webComponent !== null && (!checkOptions.propName || Object.hasOwn(webComponent, checkOptions.propName))) {
    return cb();
  }
  if (window.customElements) {
    // ...or if it is defined in customElements...
    if (window.customElements.get(customElement)) {
      if (!checkOptions.documentDefinition) {
        return cb();
      }
      // If we need to wait the element to be accessible from the document, then we wait
      // 1/4 seconds before running again the test...
      setTimeout(() => executeWhenCustomElementIsPresent(customElement, checkOptions, cb, nbCallStack + 1), 250);
    } else {
      // ...else, we wait in case it is defined later.
      window.customElements
        .whenDefined(customElement)
        .then(() =>
          checkOptions.propName
            ? executeWhenCustomElementIsPresent(customElement, checkOptions, cb, nbCallStack + 1)
            : cb(),
        );
    }
  }
}

/**
 * Detect if displayed on an iOS device.
 * https://stackoverflow.com/questions/4460205/detect-ipad-iphone-webview-via-javascript
 */
export const isEmbeddedInIOS = (): boolean => {
  const standalone = (window.navigator as any).standalone;
  const userAgent = window.navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent); // check for webview & not browser
  const ios = /iphone|ipod|ipad/.test(userAgent);
  return ios && !standalone && !safari;
};

/**
 * The version in User Agent may be written "1.2.3" or "1_2_3". Normalize it to "1.2.3"...
 */
const normalizeSemVer = (version: string): string => {
  const convert = (x: string) => parseInt(x, 10) || 0;
  // eslint-disable-next-line no-useless-escape
  const array = version.split(/[\._]/); // "1_2_3" -> ["1", "2", "3"]
  // "1.2.3" -> ["1", "2", "3"]
  return `${convert(array[0])}.${convert(array[1])}.${convert(array[2])}`;
};

/**
 * Check if the current browser is an Android browser in a webview. Not sure to work every time...
 * Resources:
 *   - https://developer.chrome.com/multidevice/user-agent#webview_user_agent
 *   - https://github.com/uupaa/UserAgent.js/blob/master/lib/UserAgent.js
 */
export const isEmbeddedInAndroid = (): boolean => {
  const ua = window.navigator.userAgent;
  const android = /Android/.test(ua);
  const chrome = /Chrome/.test(ua);
  if (android && chrome) {
    const version = normalizeSemVer(
      ua
        .split('Chrome/')[1]
        .trim()
        // eslint-disable-next-line no-useless-escape
        .split(/[^\w\.]/)[0],
    );
    if (parseFloat(version) >= 42) {
      return /;\swv/.test(ua);
    } else if (/\d{2}\.0\.0/.test(version)) {
      return true;
    }
    return (window as any).requestFileSystem || (window as any).webkitRequestFileSystem;
  }
  return false;
};

export function getInternationalizationLabels(i18n: any, additionalI18n?: any, language?: string): any {
  const defaultI18n: any = { ...i18n.en };
  const currentLanguage: string = (language || 'EN').toLowerCase();
  if (
    // eslint-disable-next-line no-prototype-builtins
    i18n.hasOwnProperty(currentLanguage) &&
    // eslint-disable-next-line no-prototype-builtins
    additionalI18n?.hasOwnProperty?.(currentLanguage)
  ) {
    return { ...i18n[currentLanguage], ...additionalI18n[currentLanguage] };
    // eslint-disable-next-line no-prototype-builtins
  } else if (additionalI18n?.hasOwnProperty?.(currentLanguage)) {
    return { ...defaultI18n, ...additionalI18n[currentLanguage] };
  } else {
    // eslint-disable-next-line no-prototype-builtins
    if (i18n.hasOwnProperty(currentLanguage)) {
      return { ...i18n[currentLanguage] };
    } else {
      console.log(`No internationalization labels for "${currentLanguage}" (through "i18n" attribute).`);
      return defaultI18n;
    }
  }
}

export function parseResponseAsJson(response: Response): Promise<object> {
  return response.json();
}

export function parseResponseAsText(response: Response): Promise<string> {
  return response.text();
}

export type WidgetEnvironment = 'development' | 'homologation' | 'production';

export function whichEnvironment(widgetConfiguration: WidgetConfiguration): WidgetEnvironment {
  const widgetTestEnvironment = localStorage.getItem('sgwt-widgets.test-environment');
  if (widgetTestEnvironment !== null && widgetTestEnvironment.toLowerCase() === 'true') {
    // Development is a specific environment for the Widgets (built up againt services-admin-dev.sgmarkets.world.socgen env. portal).
    // It cannot rely on `environment: 'development'` from SGWTWidgetConfiguration, because until now, if it is
    // not "production", it is "homologation". So we don't want applications where `environment: 'development'`
    // to be impacted. In addition, this "development" mode is really just for us to test everything before
    // pushing into homologation.
    if (!(widgetConfiguration as any).__sgwtWidgetsTestEnvironmentWarnDisplayed) {
      // Avoid displaying the warning below too often...
      (widgetConfiguration as any).__sgwtWidgetsTestEnvironmentWarnDisplayed = true;
      console.log(`
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
███████  ██████  ██     ██ ████████     ██     ██ ██ ██████   ██████  ███████ ████████ ███████
██      ██       ██     ██    ██        ██     ██ ██ ██   ██ ██       ██         ██    ██
███████ ██   ███ ██  █  ██    ██        ██  █  ██ ██ ██   ██ ██   ███ █████      ██    ███████
     ██ ██    ██ ██ ███ ██    ██        ██ ███ ██ ██ ██   ██ ██    ██ ██         ██         ██
███████  ██████   ███ ███     ██         ███ ███  ██ ██████   ██████  ███████    ██    ███████
▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒
------------------ SGWT WIDGETS ARE RUNNING IN THE DEVELOPMENT ENVIRONMENT! ------------------
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀`);
    }
    return 'development';
  }
  const envFromConfig = widgetConfiguration.environment.toLowerCase();
  if (!envFromConfig || envFromConfig === 'prod' || envFromConfig === 'production') {
    return 'production';
  }

  return 'homologation';
}

/**
 * If the widget is loaded from the internal production (https://shared.fr.world.socgen), then we can
 * use the internal production API instead of the external one to avoid potential WAF filtering issues.
 */
export function isLoadedFromInternalProduction(widget: string) {
  const tag = document.querySelector<HTMLScriptElement>(`script[src*="${widget}.js"]`);
  if (!tag) {
    return false;
  }
  return tag.src.toLowerCase().indexOf('shared.fr.world.socgen') > -1;
}

/**
 * Copy the content of any node to the Clipboard.
 */
export function copyElementContentToClipboard(element?: HTMLElement) {
  if (element) {
    const range = document.createRange();
    range.selectNode(element);
    const selection = window.getSelection();
    if (selection !== null) {
      selection.removeAllRanges(); // clear current selection
      selection.addRange(range); // to select text
      document.execCommand('copy');
      selection.removeAllRanges(); // to deselect
    }
  }
}

/**
 * Returns the browser language, as the language tag, without the potential subtags,
 * e.g. `en` and not `en-US` or `fr` and not `fr-FR`.
 */
export function getBrowserLanguage(): string | undefined {
  const lang = navigator.language || (navigator as any).userLanguage;
  return lang ? lang.split('-')[0].toLowerCase() : undefined;
}

/**
 * Return the value of a cookie or `null` if not present.
 */
export function readCookie(name: string): string | null {
  return (
    document.cookie
      .split('; ')
      .find((row) => row.startsWith(`${name}=`))
      ?.split('=')[1] || null
  );
}
